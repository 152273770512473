<template>
  <div>

  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  created(){
      this.$router.replace('/start')
  }
}
</script>

<style scoped lang="less">

</style>
